import React, { Component } from "react";
import Layout from "../components/layout"
import { graphql } from "gatsby";
import SEO from "../components/seo"

class ContributorTemplate extends Component {
  componentDidMount() {
    var links = document.querySelectorAll( '.all-page a' );
     for (var i = 0, length = links.length; i < length; i++) {
       if (links[i].hostname != window.location.hostname) {
           links[i].target = '_blank';
       }
     }
  }
  render() {
    const data = this.props.data.contentfulContributor;
    const posts = this.props.data.allContentfulPost.edges;
    let allPostsWithContributor = [];
    posts.forEach(post => {
      const contribs = post.node.contributor;
      for (var i=0; i<contribs.length; i++) {
        if (contribs[i].name === data.name) {
          allPostsWithContributor.push(post);
        }
      }
      return;
    })

    console.log(data);
    return (
      <Layout>
        <SEO title={`futurefeed | ${data.name}`} />
        <div className="all-page">
          <h2>{data.name}</h2>
          <div
            className="bio"
            dangerouslySetInnerHTML={{ __html: data.biography.childMarkdownRemark.html }}
          />
          {allPostsWithContributor.map((post, i) => {
            if (post.node.slug !== "testing-post" && post.node.slug !== "testing-post-2") {
              return (
                <a className="link" key={i} href={`/${post.node.slug}`}><h3>{post.node.title}</h3></a>
              )
            }

          })}
        </div>
      </Layout>
    )
  }
}

export default ContributorTemplate

export const pageQuery = graphql`
  query contributorQuery($slug: String!) {
    contentfulContributor(slug: { eq: $slug }) {
      name
      biography {
        childMarkdownRemark {
          html
        }
      }
    }

    allContentfulPost {
      edges {
        node {
          id
          slug
          title
          contributor {
            ... on ContentfulContributor {
              name
            }
          }
          tags {
            ... on ContentfulTag {
              name
            }
          }
        }
      }
    }
  }
`
